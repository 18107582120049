import validator from "@rjsf/validator-ajv8";
import schema from './schema.json';
import uiSchema from './uiSchema.json';
import { Grid } from '@chakra-ui/react';
import YAML from 'yaml';
import * as React from 'react';
import { useEffect } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { Textarea } from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { GoogleLogin } from 'react-google-login';
import { GoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';

import { ChakraProvider } from '@chakra-ui/react';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { Flex, Spacer } from '@chakra-ui/react';
import { Container } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

import theme from "./theme";

import { useNavigate } from 'react-router-dom';

import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  RouterProvider, createRoutesFromElements,
  useParams, Routes, useLoaderData, createBrowserRouter
} from "react-router-dom";

const MainApp = () => {

  const [jsonData, setJsonData] = React.useState();
  const [logData, setLogData] = React.useState([]);
  const [fileName, setFileName] = React.useState();
  const serverFrontURL = process.env.REACT_APP_URL;
  const serverBaseURL =  "https://api.kolkhoz.io"; //process.env.REACT_API_URL;
  // TODO
  // Adding states to track button availability
  const [compileComplete, setCompileComplete] = React.useState(false);
  const [isValidateDisabled, setIsValidateDisabled] = React.useState(false);
  const [isCompileDisabled, setIsCompileDisabled] = React.useState(false);
  const [isDownloadDisabled, setIsDownloadDisabled] = React.useState(true);
  const [isFlashDisabled, setIsFlashDisabled] = React.useState(true);

  const [validateButtonColor, setValidateButtonColor] = React.useState('#DDDDDD');
  const [compileButtonColor, setCompileButtonColor] = React.useState('#DDDDDD');
  const [downloadButtonColor, setDownloadButtonColor] = React.useState('#AAAAAA');
  const [flashButtonColor, setFlashButtonColor] = React.useState('#AAAAAA');


  const navigate = useNavigate();

  let { file_id } = useParams();  

  if ( file_id == null ) { 
    file_id = "";
  }


  let loadedData = useLoaderData();
  console.log(loadedData);  

  useEffect(() => { 
    setJsonData(JSON.parse(loadedData));
  }, [])

  useEffect(() => { 
    setTextAreaValue(YAML.stringify(jsonData));
  }, [jsonData])

  const handleCompile = () => {
    setLogData([]);
    setIsValidateDisabled(true);
    setIsCompileDisabled(true);
    setIsDownloadDisabled(true); // Disable the Download button
    setIsFlashDisabled(true); // Disable the Flash button
    setDownloadButtonColor('#AAAAAA');
    setFlashButtonColor('#AAAAAA');
    setValidateButtonColor('#AAAAAA'); // Disable the Validate button

    var yaml_text = JSON.stringify(jsonData);

    // Send data to the backend via POST
    fetch(`${serverBaseURL}/compile/${file_id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: yaml_text
    })
      .then((response) => {
        const reader = response.body.getReader();
        let partial = '';
        return reader.read().then(function processResult(result) {
          const text = partial + new TextDecoder().decode(result.value || new Uint8Array(), {
            stream: !result.done,
          });
          const lines = text.split(/\r?\n/);
          partial = lines.pop() || '';
          console.log(lines.join('\n')); // console logs
          setLogData((prevData) => [...prevData, ...lines]);
          if (result.done) {
            proccessServerReply(response);
            setCompileComplete(true);
            setIsDownloadDisabled(false);
            setIsFlashDisabled(false);
            setDownloadButtonColor('#DDDDDD');
            setFlashButtonColor('#DDDDDD');
            setIsCompileDisabled(false);
            setIsValidateDisabled(false); // Enable the Validate button
            setValidateButtonColor('#DDDDDD'); // Set the Validate button color to its original color
            return;
          }
          return reader.read().then(processResult);
        });
      })
      .catch((error) => {
        console.error(error);
        setIsValidateDisabled(false);
        setIsCompileDisabled(false);
        setIsDownloadDisabled(false); // Enable the Download button if an error occurs
        setIsFlashDisabled(false); // Enable the Flash button if an error occurs
        setDownloadButtonColor('#DDDDDD');
        setFlashButtonColor('#DDDDDD');
        setValidateButtonColor('#DDDDDD'); // Set the Validate button color to its original color
      });
  };

  function proccessServerReply(response) {
    let fn = response.headers.get('X-File-Uuid');
    console.log(Array.from(response.headers.entries()))
    console.log(fn);
    setFileName(fn);
    navigate(`/${fn}`);
    setIsValidateDisabled(false);
    return;
  }

  function getLogsValidate() {
    setLogData([]);
    var yaml_text = JSON.stringify(jsonData);
    fetch(`${serverBaseURL}/config/${file_id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: yaml_text
    })
      .then(response => {
        const reader = response.body.getReader();
        let partial = '';
        return reader.read().then(function processResult(result) {
          const text = partial + new TextDecoder().decode(result.value || new Uint8Array, { stream: !result.done });
          const lines = text.split(/\r?\n/);
          partial = lines.pop() || '';
          console.log(lines.join('\n')); // console logs
          setLogData(prevData => [...prevData, ...lines]); // add logs to state
          //console.log(result.done);
          if (result.done) {
            proccessServerReply(response);
            return;
          }
          return reader.read().then(processResult);
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  //  Post request compile function that downloads a file
  const handleDownload = () => {
    fetch(`${serverBaseURL}/download/${file_id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 404) {
          console.log('The configuration was not compiled');
          throw new Error('The configuration was not compiled');
        }
        return response.blob().then(blob => ({ blob, response }));
      })
      .then(({ blob, response }) => {
        // Saving the binary file as an object URL
        const url = URL.createObjectURL(blob);

        const contentDisposition = response.headers.get('content-disposition');
        const [, filename] = contentDisposition.match(/filename="(.+?)"/i) || [];

        // Create a link to download the file
        const link = document.createElement('a');
        link.href = url;
        link.download = filename || 'file.bin';

        // Programmatically click on the link to start the download
        link.click();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };


  //  Post request compile function that downloads a file ota
  const handleDownloadOta = () => {
    fetch(`${serverBaseURL}/download-ota/${file_id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 404) {
          console.log('The configuration was not compiled');
          throw new Error('The configuration was not compiled');
        }
        return response.blob().then(blob => ({ blob, response }));
      })
      .then(({ blob, response }) => {
        // Saving the binary file as an object URL
        const url = URL.createObjectURL(blob);

        const contentDisposition = response.headers.get('content-disposition');
        const [, filename] = contentDisposition.match(/filename="(.+?)"/i) || [];

        // Create a link to download the file
        const link = document.createElement('a');
        link.href = url;
        link.download = filename || 'file.bin';

        // Programmatically click on the link to start the download
        link.click();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  //---------------------------------------------------------
  //  To switch between JSON Form and Logs

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //---------------------------------------------------------
  //  To make changes to a JSON Form and a yaml output form so that one doesn't override the other

  const [textAreaValue, setTextAreaValue] = React.useState(YAML.stringify(jsonData));

  const handleTextAreaChange = (event) => {
    const value = event.target.value;
    const replacedValue = value.replace(/[\u00A0]/g, ' '); // Replacing non-breaking spaces with regular spaces
    setTextAreaValue(replacedValue);

    try {
      const parsedData = YAML.parse(replacedValue);
      setJsonData(parsedData);
    } catch (error) {
      console.error('Error parsing YAML', error);
      // Handle parse error, e.g. show error message to user.
    }
  };

//---------------------------------------------------------

  //const data = window.localStorage.getItem(`MY_APP_STATE_${window.location.pathname}`);
  //if (data !== null) setJsonData(JSON.parse(data));


  useEffect(() => {
    window.localStorage.setItem(`MY_APP_STATE_${window.location.pathname}`, JSON.stringify(jsonData));
  }, [jsonData]);

//---------------------------------------------------------
  // Share

  const [sharedLink, setSharedLink] = React.useState('');
  function handleLinkClick() {
    var json_text = JSON.stringify(jsonData);
    fetch(`${serverBaseURL}/share/${file_id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: json_text,
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        // Save the shared link and update state
        setSharedLink(data.url);
      })
      .then(response => proccessServerReply(response))
      .catch(error => {
        console.error(error);
      });
  }

  function getRemoteConfig(uuid) {
    fetch(`${serverBaseURL}/${uuid}`, {
      method: 'GET'
    })
      .then(response => response.json())
      .then(response => proccessServerReply(response))
      .then(data => {
        // Update the state with the retrieved data
        console.log(data.json_text)
        setJsonData(data.json_text);
      })
      .catch(error => {
        console.error(error);
      });
  }
  const [favourites, setFavourites] = React.useState([]);

  useEffect(() => {
    const favourites = JSON.parse(localStorage.getItem('favourites'));
    if (favourites) {
      setFavourites(favourites);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('favourites', JSON.stringify(favourites));
  }, [favourites]);


  //create favourites
  const handleCreateFavourites = () => {    
    setFavourites([...favourites, [file_id, jsonData["esphome"]["name"]]]);
  };

  const handleDeleteFavourite = (index) => {
    const newFavs = favourites.filter((_, i) => i !== index);
    setFavourites(newFavs);
  };

  const handleToggleEdit = (index) => {
    setFavourites((prevFavourites) => {
      const updatedFavourites = prevFavourites.map((favourite, idx) => {
        if (idx === index) {
          return {
            ...favourite,
            isEditing: !favourite.isEditing,
          };
        }
        return {
          ...favourite,
          isEditing: false,
        };
      });
      return updatedFavourites;
    });
  };


  const LeftPanel = <Box align='left'>
    <Box p='1' py='5'>
      <ButtonGroup size='lg'>
        <Button onClick={getLogsValidate} disabled={isValidateDisabled}>Validate</Button>
        <Button onClick={handleCompile} disabled={isCompileDisabled}>Compile</Button>
        <Button onClick={handleDownload} disabled={isDownloadDisabled}>Download BIN</Button>
        <Button onClick={handleDownloadOta} disabled={isDownloadDisabled}>Download BIN Ota</Button>
        <esp-web-install-button manifest={`${serverBaseURL}/manifest/${fileName}`}>
          <Button slot="activate" disabled={isFlashDisabled}>Flash</Button> // ? slot activate?
        // ? slot activate?
        </esp-web-install-button>
          <Button onClick={handleLinkClick}>Share Link</Button>
          <Button onClick={handleCreateFavourites}>Add favourites</Button>
        </ButtonGroup>
    </Box>
    <Textarea
      value={textAreaValue}
      onChange={handleTextAreaChange}
      size='lg'
      resize='horizontal'
      height="100%" />


  </Box>;
  const RightPanel = <Box overflowY="auto" height="600px">
    <Flex>
      <Tabs size='lg'>
        <TabList onChange={handleChange}>
          <Tab>Logs</Tab>
          <Tab>Favourites</Tab>
          {/*<Tab>Json Form(under construction)</Tab> */}
        </TabList>
        <TabPanels>
          <TabPanel>
            {logData.map((line, index) => (
              <Container key={index}>{line}</Container>
            ))}
          </TabPanel>
          <TabPanel>

            <Box p='1' py='5'>
            <ButtonGroup>
              {favourites.map((favourite, index) => (
                <div key={index}>
                  <Button onClick={() => { }} >
                    {favourite[0]}  /  {favourite[1]}
                  </Button>

                  <Button onClick={() => handleDeleteFavourite(favourite.name_config)}>
                    Delete
                  </Button>
                  
                </div>
              ))}
            </ButtonGroup>
            </Box>
          </TabPanel>
          <TabPanel>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Spacer />
      <Box>{sharedLink && (
            <a href={sharedLink}>
              {serverFrontURL}/{sharedLink}
            </a>
          )}</Box>
      
    </Flex>


  </Box>;

  const MainApp = <ChakraProvider theme={theme}>
    <Box p='1' px='5' height="700px">
      <SimpleGrid columns={2} spacing={5}>
        {LeftPanel}
        {RightPanel}
      </SimpleGrid>
    </Box>
  </ChakraProvider>;

    return MainApp;

}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>  
       <Route exact path="/" element={<MainApp/>} loader={({ params }) => {
          return '{"esphome":{"name":"e"},"esp32":{"board":"esp32doit-devkit-v1","framework":{"type":"arduino"}}}';
        }}/>
       <Route path="/:file_id" element={<MainApp/>}  loader={({ params }) => {
          return window.localStorage.getItem(`MY_APP_STATE_${window.location.pathname}`);
        }} />
    </Route>
  )
);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
